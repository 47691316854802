
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";
  import * as jsonpatch from "fast-json-patch";
  import { ParametroFinanceiroService } from "@/core/services/financeiro";
  import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { Empresa } from "@/core/models/cadastros";
  
  @Component
  export default class DocumentoSubConta extends Vue {
    @Prop() public item!: any;
    @Prop() public parametroId!: number;
    @Prop() private value!: string;
  
    service = new ParametroFinanceiroService();
    serviceEmpresa = new EmpresaService();
  
    valid = true;
    dialog = false;
    loadingSubConta = false;
    loadingSalvar = false;
    permitirCriarSubConta =false;
  
    responseDocumentos = "";
    responseType: "success" | "error" = "success";
    fileNames: any = {};
  
    @Watch("value")
    Value() {
      this.dialog = this.value ? true : false;
      if (this.dialog) {
        this.observer = jsonpatch.observe(this.item);
      } else {
        jsonpatch.unobserve(this.item, this.observer);
      }
    }

    versodocumentoNome:string= "";
    SelfieNome:string= "";
    frentedocumentoNome:string= "";
    documentoNome:string= "";
  
    @Watch("dialog")
    Dialog() {
      if (!this.dialog) {
        this.$emit("fechou");
      }
    }

    get todosArquivosCarregados(): boolean {
  return (
    this.item.selfieResponsavel &&
    this.item.fotoDocumentoResponsavel &&
    this.item.versoDocumentoResponsavel && 
    this.item.rendaMensal &&
    this.item.siteDaEmpresa &&
    this.item.responsavel.nomeDaMae
  );
}

    Atualizar(){
      this.serviceEmpresa.ObterPorId(this.item.id, 'Endereco, SelfieResponsavel, VersoDocumentoResponsavel, FotoDocumentoResponsavel, ContratoSocialLtda').then( 
      res => {
        this.item = res.data;
        this.observer = jsonpatch.observe(this.item); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    }
  
    mounted() {
    }
  
    observer!: jsonpatch.Observer<any>;
  
    Close() {
      this.dialog = false;
    }
  
    async processFile(file: File): Promise<any> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve({
            Nome: file.name,
            Tipo: file.type,
            Tamanho: file.size,
            Dados: reader.result?.toString().split(",")[1], // Remove o prefixo "data:image/png;base64,"
            DataEmissao: new Date(),
          });
        };
        reader.onerror = (error) => reject(error);
      });      
    }
  
    async Salvar() {
      this.loadingSalvar = true;
      const form: any = this.$refs.form;
      if (!form.validate()) {
        this.$swal("Aviso", "Preencha todos os campos obrigatórios corretamente!", "warning");
        this.responseType = "error";
        return;
      }
  
      if (this.item.selfieResponsavel instanceof File) {
        this.item.selfieResponsavel = await this.processFile(this.item.selfieResponsavel);
      }
      if (this.item.fotoDocumentoResponsavel instanceof File) {
        this.item.fotoDocumentoResponsavel = await this.processFile(this.item.fotoDocumentoResponsavel);
      }
      if (this.item.versoDocumentoResponsavel instanceof File) {
        this.item.versoDocumentoResponsavel = await this.processFile(this.item.versoDocumentoResponsavel);
      }
      if (this.item.contratoSocialLtda instanceof File) {
        this.item.contratoSocialLtda = await this.processFile(this.item.contratoSocialLtda);
      }
  
      let pacthModel = jsonpatch.generate(this.observer);
  
      this.serviceEmpresa.Patch(pacthModel, this.item.id).then(
        (res) => {
          this.responseDocumentos = res.status == 201 || res.status == 200 ? "Documentos cadastrados com sucesso!" : "Erro ao cadastrar documentos!";
          jsonpatch.unobserve(this.item, this.observer);
          this.Atualizar()
          
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          }
        }
      ).finally(() => {
      this.loadingSalvar = false;
      this.permitirCriarSubConta = true;
    });
    }
  
    GerarSubConta() {
  this.loadingSubConta = true;
  this.service.CriarSubConta(this.parametroId, 2)
    .then((res) => {
      this.$swal(
        "Aviso",
        "Operação realizada com sucesso! A subconta deve ser aceita pela Celcash antes de ser utilizada.",
        res.status === 201 || res.status === 200 ? "success" : "warning"
      );
      this.$emit("salvou");
      this.Close();
    })
    .catch((err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status === 403) {
        this.$swal("Aviso", err.response.data.message, "warning");
      } else {
        this.$swal(
          "Aviso",
          err.response.data,
          err.response.status === 400 ? "warning" : "error"
        );
      }
    })
    .finally(() => {
      this.loadingSubConta = false;
    });
}

  
openFile(file: any, event: MouseEvent) {
  event.preventDefault(); // Evita o refresh da página

  if (file && file.dados && file.tipo) {
    try {
      // Converte a string Base64 para um ArrayBuffer
      const byteCharacters = atob(file.dados);
      const byteArrays: Uint8Array[] = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
      }

      // Define o tipo do arquivo (PDF ou imagem)
      const fileType = file.tipo.includes("pdf") ? "application/pdf" : file.tipo;

      // Cria um Blob sem modificar `this.item`
      const previewFile = new Blob(byteArrays, { type: fileType });
      const url = URL.createObjectURL(previewFile);

      // Abre o arquivo em uma nova aba
      window.open(url, "_blank");
    } catch (error) {
      console.error("Erro ao abrir o arquivo:", error);
      this.$swal("Erro", "Falha ao abrir o arquivo", "error");
    }
  } else {
    this.$swal("Erro", "Arquivo não encontrado", "error");
  }
}
  }
  
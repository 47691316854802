
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import comprasServices from "@/core/services/compras";
import geralServices from "@/core/services/geral/index";
import { PessoaService } from "@/core/services/cadastros/PessoaService";
import Pessoa from "@/core/models/cadastros/Pessoa";
import * as jsonpatch from 'fast-json-patch';
import { EmpreendimentoPessoa, PessoaFamiliar } from "@/core/models/cadastros";
import { EmpreendimentoService, GrauParentescoService } from "@/core/services/cadastros";
import { EscolaridadeService } from "@/core/services/geral/EscolaridadeService";

@Component
export default class CadastroCliente extends Vue {
  @Prop() public item!: Pessoa;
  @Prop() public value!: string;

  service = new PessoaService();
   
  tipoPessoas:any = [];
  profissoes:any = [];
  nacionalidades:any = [];
  estadosCivis:any = [];
  listaSexo:any = [];
  listaGrauParentesco:any = [];
  listaEscolaridade:any = [];
  
  valid = true;
  dialog = false;
  tabActive = 0;

  options: any = {
    itemsPerPage: 15,
  };

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formFamiliar: HTMLFormElement;
    formEmpreendimento: HTMLFormElement;
  };

  observer! : jsonpatch.Observer<Pessoa>; 

  headersEmpreendimentos: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
  ]

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "CPF", value: "cpf" },
    { text: "RG", value: "rg" },
    { text: "Orgão Expedidor", value: "orgaoExpedidor" },
    { text: "Data de Nascimento", value: "dataNascimento" },
    { text: "Grau de Parentesco", value: "grauParentescoId" },
    { text: "Escolaridade", value: "escolaridadeId" },
    { text: "Profissão", value: "profissaoId" },
    { text: "Dependente", value: "dependente" }
  ];

  listaEmpreendimentos = [];
  empreendimentosSelecionados = [];
  empreendimentoIndex = -1;
  empreendimentoPessoa = new EmpreendimentoPessoa();

  @Watch("empreendimentoPessoa")
  EmpreendimentoPessoa() {
    if (this.$refs.formEmpreendimento) {
      this.$refs.formEmpreendimento.resetValidation();
    }
  }

  AdicionarEmpreendimento(){
  if (this.$refs.formEmpreendimento.validate()) {
    if (this.empreendimentoIndex > -1) {
      Object.assign(this.item.empreendimentosPessoas[this.empreendimentoIndex], this.empreendimentoPessoa)
    }else{
      this.item.empreendimentosPessoas.push(this.empreendimentoPessoa);
    }
    this.empreendimentoPessoa = new EmpreendimentoPessoa();
    this.empreendimentoIndex = -1;
    }
  }

  Editar(item) {
    this.empreendimentoIndex = this.item.empreendimentosPessoas.indexOf(item);
    this.empreendimentoPessoa = Object.assign({}, item);;
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      focusConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.item.empreendimentosPessoas!.indexOf(item);
          delete this.item.empreendimentosPessoas![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
          const index = this.item.empreendimentosPessoas!.indexOf(item);
          this.item.empreendimentosPessoas!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }


 
  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Validacoes(){  
   if(this.item.tipoPessoaId == 1){
     if(!this.item.tipoPessoaId  || !this.item.nome || !this.item.cnpjCpf  ||  !this.item.dataNascimento ||  !this.item.sexoId || !this.item.qualificacao!.profissaoId   || !this.item.qualificacao!.nacionalidadeId  || !this.item.qualificacao!.estadoCivilId){
       this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
        this.tabActive = 0;
        return true;
      }
    } else if (this.item.tipoPessoaId == 2) {
      if (!this.item.tipoPessoaId || !this.item.nome || !this.item.cnpjCpf || !this.item.fantasia) {
        this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
        this.tabActive = 0;
        return true;
      }
    }
    if (!this.item.endereco.cep || !this.item.endereco.logradouro || !this.item.endereco.bairro || !this.item.endereco.estadoId || !this.item.endereco.municipioId) {
      this.$swal("Atenção", "Atenção é necessário preencher o endereço completo.", "warning");
      this.tabActive = 0;
      return true;
    }
  }
  
   
  Salvar() { 
    this.$refs.form.validate();

    if (!this.Validacoes()) {
      let pacthModel = jsonpatch.generate(this.observer);
      if(this.item.juridicaResponsavel.endereco.municipioId == 0){
      this.item.juridicaResponsavel.endereco.municipioId = 1303502
    }
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then( 
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },  
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else if (err.response.data.length == 94) {
            this.$swal("Aviso", "Campo CPF/CNPJ está incorreto.", "warning");
          } else {
            this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  Close() {
    this.dialog = false;
    this.tabActive = 0;
  }

  @Watch("pessoaFamiliar")
  PessoaFamiliar() {
    if (this.$refs.formFamiliar) {
      this.$refs.formFamiliar.resetValidation();
    }
  }

  parentescoIndice: number = -1;
  pessoaFamiliar = new PessoaFamiliar();

  AdicionarParentesco() {
    if (this.$refs.formFamiliar.validate()) {
      if (this.parentescoIndice > -1) {
        Object.assign(this.item.familiares[this.parentescoIndice], this.pessoaFamiliar)
      }else{
        this.item.familiares.push(this.pessoaFamiliar);
      }
      this.pessoaFamiliar = new PessoaFamiliar();
      this.parentescoIndice = -1;
    }
  } 
 
  EditarParentesco(item) {
    this.parentescoIndice = this.item.familiares.indexOf(item);
    this.pessoaFamiliar = Object.assign({}, item);
  }

  ExcluirParentesco(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.familiares.indexOf(item);
        this.item.familiares.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    });
  }

  mounted() {
    const grauParentesco = new GrauParentescoService();
    grauParentesco.ListarTudo().then(
      res => {
        this.listaGrauParentesco = res.data.items;
      }
    )
  

    new comprasServices.TipoPessoaService().ListarTudo().then(
      res => this.tipoPessoas = res.data.items);

    new geralServices.SexoService().ListarTudo().then(
      res => this.listaSexo = res.data.items);

    new comprasServices.EstadoCivilService().ListarTudo().then(
      res => this.estadosCivis = res.data.items);

    new comprasServices.NacionalidadeService().ListarTudo().then(
      (res) => this.nacionalidades = res.data.items);

    new comprasServices.ProfissaoService().ListarTudo().then(
    (res) => this.profissoes = res.data.items);

    new EscolaridadeService().ListarTudo().then(
    (res) => this.listaEscolaridade = res.data.items);    
    
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
    (res) => this.listaEmpreendimentos = res.data.items); 
  }
} 



import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ModeloContratoService, TipoContratoDigitalService } from "@/core/services/cadastros";
import * as jsonpatch from 'fast-json-patch';
import Tinymce from '@tinymce/tinymce-vue';
import { ModeloContratoDigital } from "@/core/models/cadastros";

@Component({
  components: {
    Editor:Tinymce
  }  
}) 

export default class ContratoDigital extends Vue {
  @Prop() public item!: ModeloContratoDigital;
  @Prop() public value!: string;

  categoriaId = 1;
  categorias = [
      {"id": 1, "nome": "Dados do Cliente"},
      {"id": 2, "nome": "Dados do 2º Cliente"},
      {"id": 3, "nome": "Dados do Empreendimento"},
      {"id": 4, "nome": "Dados do Proprietário"},
      {"id": 5, "nome": "Dados do Socio"},
      {"id": 6, "nome": "Dados do Corretor"},
      {"id": 7, "nome": "Dados do Contrato"},
      {"id": 8, "nome": "Dados da Empresa"}
  ]

  ClienteDados = [
      {"nome": "{Cliente_Nome}"},
      {"nome": "{Cliente_DataNascimento}"},
      {"nome": "{Cliente_Nacionalidade}"},
      {"nome": "{Cliente_EstadoCivil}"},
      {"nome": "{Cliente_Profissao}"},
      {"nome": "{Cliente_Sexo}"},
      {"nome": "{Cliente_Rg}"},
      {"nome": "{Cliente_CpfCnpj}"},
      {"nome": "{Cliente_Email}"},
      {"nome": "{Cliente_Telefone}"},
      {"nome": "{Cliente_OrgaoExpedido}"},
      {"nome": "{Cliente_DataExpedicao}"},
      {"nome": "{Cliente_Cep}"},
      {"nome": "{Cliente_Logradouro}"},
      {"nome": "{Cliente_Numero}"},
      {"nome": "{Cliente_Complemento}"},
      {"nome": "{Cliente_Bairro}"}, 
      {"nome": "{Cliente_Municipio}"},
      {"nome": "{Cliente_Estado}"},
      {"nome": "{Cliente_Sigla_Estado}"},

      {"nome": "{Cliente_Conjuge_Nome}"},
      {"nome": "{Cliente_Conjuge_Rg}"},
      {"nome": "{Cliente_Conjuge_Cpf}"},
      {"nome": "{Cliente_Conjuge_OrgaoExpedidor}"}
  ]

  Cliente2Dados = [
      {"nome": "{Cliente2_Nome}"},
      {"nome": "{Cliente2_DataNascimento}"},
      {"nome": "{Cliente2_Nacionalidade}"},
      {"nome": "{Cliente2_EstadoCivil}"},
      {"nome": "{Cliente2_Profissao}"},
      {"nome": "{Cliente2_Sexo}"},
      {"nome": "{Cliente2_Filiacao}"},
      {"nome": "{Cliente2_Rg}"},
      {"nome": "{Cliente2_CpfCnpj}"},
      {"nome": "{Cliente2_Email}"},
      {"nome": "{Cliente2_Telefone}"},
      {"nome": "{Cliente2_OrgaoExpedido}"},
      {"nome": "{Cliente2_DataExpedicao}"},
      {"nome": "{Cliente2_Cep}"},
      {"nome": "{Cliente2_Logradouro}"},
      {"nome": "{Cliente2_Numero}"},
      {"nome": "{Cliente2_Complemento}"},
      {"nome": "{Cliente2_Bairro}"},
      {"nome": "{Cliente2_Municipio}"},
      {"nome": "{Cliente2_Estado}"},
      {"nome": "{Cliente2_Sigla_Estado}"},

      {"nome": "{Cliente2_Conjuge_Nome}"},
      {"nome": "{Cliente2_Conjuge_Rg}"},
      {"nome": "{Cliente2_Conjuge_Cpf}"},
      {"nome": "{Cliente2_Conjuge_OrgaoExpedidor}"}
  ]
  

  EmpreendimentoDados = [
      {"nome": "{Empreendimento_Nome}"},
      {"nome": "{Empreendimento_Matricula}"},
      {"nome": "{Empreendimento_Cartorio}"},
      {"nome": "{Empreendimento_Cartorio_Sigla_Estado}"},
      {"nome": "{Quadra_Nome}"},
      {"nome": "{Lote_Nome}"},
      {"nome": "{Lote_Cep}"},
      {"nome": "{Lote_Logradouro}"},
      {"nome": "{Lote_Numero}"},
      {"nome": "{Lote_Complemento}"},
      {"nome": "{Lote_Bairro}"},
      {"nome": "{Lote_Municipio}"},
      {"nome": "{Lote_AreaTotal}"},
      {"nome": "{Lote_Frente}"},
      {"nome": "{Lote_Fundo}"},
      {"nome": "{Lote_LadoDireito}"},
      {"nome": "{Lote_LadoEsquerdo}"},
      {"nome": "{Lote_Curva}"},
      {"nome": "{Lote_Confrontante_Frente}"},
      {"nome": "{Lote_Confrontante_Fundo}"},
      {"nome": "{Lote_Confrontante_LadoDireito}"},
      {"nome": "{Lote_Confrontante_LadoEsquerdo}"},
      {"nome": "{Lote_Matricula}"},
      {"nome": "{Lote_Cartorio}"},
      {"nome": "{Lote_Cartorio_Sigla_Estado}"},
      {"nome": "{Lote_Cartorio_Municipio}"},
      {"nome": "{Lote_Sigla_Estado}"},
      {"nome": "{Lote_Livro_Numero}"},
      {"nome": "{Empreendimento_Cartorio_Cidade}"},
      {"nome": "{Lote_Frente_Extenso}"},
      {"nome": "{Lote_Frente}"},
      {"nome": "{Lote_FundoExtenso}"},
      {"nome": "{Lote_LadoDireitoExtenso}"},
      {"nome": "{Lote_LadoEsquerdoExtenso}"},
      {"nome": "{Lote_AreaTotalExtenso"},
      {"nome": "{Quadra_Nome_Extenso}"}
  ]

  ProprietarioDados = [
      {"nome": "{Proprietario_Nome}"},
      {"nome": "{Proprietario_DataNascimento}"},
      {"nome": "{Proprietario_Nacionalidade}"},
      {"nome": "{Proprietario_EstadoCivil}"},
      {"nome": "{Proprietario_Profissao}"},
      {"nome": "{Proprietario_Sexo}"},
      {"nome": "{Proprietario_Rg}"},
      {"nome": "{Proprietario_CpfCnpj}"},
      {"nome": "{Proprietario_Cep}"},
      {"nome": "{Proprietario_Logradouro}"},
      {"nome": "{Proprietario_Numero}"},
      {"nome": "{Proprietario_Complemento}"},
      {"nome": "{Proprietario_Bairro}"},
      {"nome": "{Proprietario_Municipio}"},
      {"nome": "{Proprietario_Estado}"},
      {"nome": "{Proprietario_Sigla_Estado}"}
  ]

  SocioDados = [
      {"nome": "{Socio_Nome}"},
      {"nome": "{Socio_DataNascimento}"},
      {"nome": "{Socio_Nacionalidade}"},
      {"nome": "{Socio_EstadoCivil}"},
      {"nome": "{Socio_Profissao}"},
      {"nome": "{Socio_Sexo}"},
      {"nome": "{Socio_Rg}"},
      {"nome": "{Socio_CpfCnpj}"},
      {"nome": "{Socio_Cep}"},
      {"nome": "{Socio_Logradouro}"},
      {"nome": "{Socio_Numero}"},
      {"nome": "{Socio_Complemento}"},
      {"nome": "{Socio_Bairro}"},
      {"nome": "{Socio_Municipio}"},
      {"nome": "{Socio_Estado}"},
      {"nome": "{Socio_Sigla_Estado}"},
      {"nome": "{Socio_OrgaoExpedidor}"},
      {"nome": "{Socio_DataExpedicao}"},
      {"nome": "{Socio_Email}"},
      {"nome": "{Socio_Telefone}"},
  ]

  CorretorDados = [
      {"nome": "{Corretor_Nome}"},
      {"nome": "{Corretor_DataNascimento}"},
      {"nome": "{Corretor_Nacionalidade}"},
      {"nome": "{Corretor_EstadoCivil}"},
      {"nome": "{Corretor_Profissao}"},
      {"nome": "{Corretor_Sexo}"},
      {"nome": "{Corretor_Rg}"},
      {"nome": "{Corretor_CpfCnpj}"},
      {"nome": "{Corretor_Cep}"},
      {"nome": "{Corretor_Logradouro}"},
      {"nome": "{Corretor_Numero}"},
      {"nome": "{Corretor_Complemento}"},
      {"nome": "{Corretor_Bairro}"},
      {"nome": "{Corretor_Municipio}"},
      {"nome": "{Corretor_OrgaoExpedidor}"},
      {"nome": "{Corretor_DataExpedicao}"},
      {"nome": "{Corretor_Creci}"}
  ]

  ContratoDados = [
      {"nome": "{Valor_Total}"},
      {"nome": "{Valor_Total_Extenso}"},
      {"nome": "{Financiamento_Valor}"},
      {"nome": "{Financiamento_Valor_Extenso}"},
      {"nome": "{Financiamento_QtdeParcela}"},
      {"nome": "{Financiamento_QtdeParcela_Extenso}"},
      {"nome": "{Financiamento_ValorParcela}"},
      {"nome": "{Financiamento_ValorParcela_Extenso}"},
      {"nome": "{Financiamento_DataParcela}"},
      {"nome": "{Financiamento_DataParcela_Extenso}"},
      {"nome": "{MesReajuste}"},
      {"nome": "{Financiamento_DataUltimaParcela}"},
      {"nome": "{Financiamento_DataUltimaParcela_Extenso}"},
      {"nome": "{Entrada_QtdeParcela}"},
      {"nome": "{Entrada_QtdeParcela_Extenso}"},
      {"nome": "{Entrada_Valor}"},
      {"nome": "{Entrada_Valor_Extenso}"},
      {"nome": "{Entrada_ValorParcela}"},
      {"nome": "{Entrada_ValorParcela_Extenso}"},
      {"nome": "{Entrada_DataParcela}"},
      {"nome": "{Entrada_DataParcela_Extenso}"},
      {"nome": "{Intermediaria_Valor}"},
      {"nome": "{Intermediaria_ValorParcela}"},
      {"nome": "{Intermediaria_ValorParcela_Extenso}"},
      {"nome": "{Intermediaria_DataParcela}"},
      {"nome": "{Intermediaria_DataParcela_Extenso}"}, 
      {"nome": "{Indice}"},
      {"nome": "{Tabela}"},
      {"nome": "{Taxa_Remuneratorios}"},
      {"nome": "{Multa_Moratorios}"},
      {"nome": "{Multa_Moratorios_Extenso}"},
      {"nome": "{Juros_Moratorios}"},
      {"nome": "{Juros_Moratorios_Extenso}"}, 
      {"nome": "{Valor_TotalCorretagem}"},
      {"nome": "{Valor_TotalCorretagem_Extenso}"},
      {"nome": "{Contrato_Numero}"},
      {"nome": "{DataAtual_Dia}"},
      {"nome": "{DataAtual_Ano}"},
      {"nome": "{DataAtual_Mes}"},
      {"nome": "{DataAtual_Completa}"},
      
  ]

  EmpresaDados = [
      {"nome": "{Empresa_Nome}"},
      {"nome": "{Empresa_CpfCnpj}"},
      {"nome": "{Empresa_InscricaoEstadual}"},
      {"nome": "{Empresa_Logradouro}"},
      {"nome": "{Empresa_Numero}"},
      {"nome": "{Empresa_Complemento}"},
      {"nome": "{Empresa_Bairro}"},
      {"nome": "{Empresa_Municipio}"},
      {"nome": "{Empresa_Estado}"},
      {"nome": "{Empresa_Sigla_Estado}"},
      {"nome": "{Empresa_Cep}"},
      {"nome": "{Empresa_Telefone}"},
      {"nome": "{Empresa_Email}"},
      {"nome": "{Empresa_Representante}"},
  ]

  service = new ModeloContratoService();
  tipos = [];
  observer! : jsonpatch.Observer<ModeloContratoDigital>; 

  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
 
  $refs!: {
    form: HTMLFormElement;
  };

  allowDrop(event) {
    event.preventDefault();
  }
  
  drag(event) {
    var text = event.target.textContent;
    event.dataTransfer.setData("text", text);
  }

  drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("text");
    var textElement = document.createElement("div");
    textElement.textContent = data;
    event.target.appendChild(textElement);
    this.item.conteudo += data;
}

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  Validacoes(){
    if(!this.item.tipoId || !this.item.nome){
      this.$swal("Aviso", "É necessário preencher todos os campos.", "warning");
      return true; 
    }

    if(!this.item.conteudo){
      this.$swal("Aviso", "Conteúdo do Modelo digital é obrigatório.", "warning");
      return true; 
    }
  }
 
  Salvar(){
   this.$refs.form.validate();
    if (!this.Validacoes()) { 

      //if(this.item.id == 0){
        let conteudoFormatado = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"> <title>Document</title></head><body style="font-family: Arial, Helvetica, sans-serif;">${this.item.conteudo}</body></html>`
        this.item.conteudo = conteudoFormatado;
      // }
      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close(); 
        }, 
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
      }
    }

  Close() { 
    this.dialog = false;
  }  

  mounted(){
    new TipoContratoDigitalService().ListarTudo().then(res => {
      this.tipos = res.data.items;
    })
  }
}

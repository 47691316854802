
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContratoService,  EmpreendimentoService,  PessoaService,  TipoIndiceService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { ContaCorrenteService, ParametroFinanceiroService, ReceitaService, TipoProdutoContratoService } from "@/core/services/financeiro";
import Pessoa from "@/core/models/cadastros/Pessoa";
import * as jsonpatch from 'fast-json-patch';
import { Contrato, ContratoDetalhado, Unidade } from "@/core/models/cadastros";
import { Encargo } from "@/core/models/financeiro";
import { EnumSitucaoContrato } from "@/core/models/cadastros/contrato/SituacaoContrato";
import { TipoOperacaoFinanceiraService } from "@/core/services/contratos";

@Component
export default class DetalharContrato extends Vue {
  @Prop() public item!: ContratoDetalhado;
  @Prop() public value!: string;

  public contrato = new Contrato;
  dialogCadastro = false;
  pessoa = new Pessoa();

  EditarCliente(item) {
    new PessoaService().ObterPorId(item.id).then(
      res => {
        this.pessoa = res.data;
        this.pessoa.dataExpedicao = new Date(`${res.data.dataExpedicao}`).yyyymmdd();
        this.pessoa.dataNascimento =  new Date(`${res.data.dataNascimento}`).yyyymmdd();
        this.pessoa.dataCadastro = new Date(`${res.data.dataCadastro}`).yyyymmdd(); 

        if (res.data.conjuge) {
          this.pessoa.conjuge = this.pessoa.conjuge || {};
          this.pessoa.conjuge.dataNascimento =  new Date(`${res.data.conjuge.dataNascimento}`).yyyymmdd();
        }

        this.dialogCadastro = true;
      }
    )
  }

  informacaoUnidade = new Unidade();
  dialogUnidadeInformacao = false;

  ObterUnidade(id){       
    new EmpreendimentoService().ObterUnidade(id, 'Imovel, Jazigo, Lote, Lote.Infraestrutura').then(
      res => {
        this.dialogUnidadeInformacao = true;
        this.informacaoUnidade = res.data; 
      }
    )
  }

  situacaoColor(item){ 
    if(item.situacaoId == 1)
      return "blue lighten-3";

      else if(item.situacaoId == 2) 
      return "orange lighten-1";

      else if(item.situacaoId == 3)
      return "deep-purple lighten-1";

      else if(item.situacaoId == 4)
      return "red lighten-1";
  
      else if(item.situacaoId == 5)
      return "grey lighten-1";

      else if(item.situacaoId == 6) 
      return "teal lighten-1";

      else if(item.situacaoId == 7)
      return "yellow lighten-1";
  }

  e6 = 4;
  service  = new ContratoService();
  receitaService = new ReceitaService();
  observer! : jsonpatch.Observer<Contrato>;
  EnumSitucaoContrato = EnumSitucaoContrato;
 
  listaGateway =[];
  empresas = [];
  listarTipoIndice = [];
  listaTipoEmpreendimento = [];
  pessoas = [];
  listaContaCorrentes = [];
  tiposOperacaoContrato = [];
  selectedTab = null
  tab = null; 
  valid = true;
  dialog = false; 
  //AplicarDiasProtesto = false;

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Situação", value: "situacaoId" },
    { text: "Data de Alteração", value: "dataAlteracao" },
    { text: "Responsável", value: "usuario.login" },
  ];
   
  ObterParametroGatway(item){
    if(item){
       this.contrato.encargoFinanceiro = item.encargoFinanceiro;
       this.contrato.tipoGatewayId = item.tipoId;
    }else{
       this.contrato.encargoFinanceiro = new Encargo();
       this.contrato.tipoGatewayId = 0;
    } 
  }

  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      res => { 
        this.listaGateway = res.data.gatways;
      }
    )
  }
 
  mesReajuste = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.ObterContrato(); 
     }
  } 

  @Watch("contrato", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.contrato);
  };

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } 
  }
 
  ObterContrato(){
    new ContratoService().ObterPorId(this.item.id).then(
      res => {
        this.contrato = res.data;
        this.ObterParametroPorId(this.contrato.empresaId);
      }
    )
  }

  Salvar() {
    this.observer = jsonpatch.observe(this.contrato);
    let pacthModel = jsonpatch.generate(this.observer);

    (this.contrato.id > 0 ? this.service.Patch(pacthModel, this.contrato.id) : this.service.Salvar(this.contrato)).then(
      res => {
        this.receitaService.AtualizarEncargosContrato(this.contrato.id, this.contrato.encargoFinanceiro); 
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");         
        this.$emit("salvou");
         jsonpatch.unobserve(this.contrato, this.observer);
        this.Close();
       },
    (err) => { 
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    });
  }

  Close() {
    this.dialog = false;
  }
 
  ObterEncargosContaCorrente(){
     new ContaCorrenteService().ObterPorId(this.contrato.contaCorrenteId).then(
      res => {
       this.contrato.encargoFinanceiro = res.data.encargoFinanceiro;
      }
    ) 
  }

  listasSituacoes = [
    {id:1, nome: "Em Andamento"},
    {id:2, nome: "Cobrança"},
    {id:3, nome: "Jurídico"},
    {id:4, nome: "Prejuízo"},
    {id:5, nome: "Cancelado"},
    {id:6, nome: "Quitado"},
    {id:7, nome: "Aditado"},
    {id:8, nome: "Com processo"}
  ];  

   get situacoesFiltradas() {
     const situacoesPermitidas = this.listasSituacoes.filter(
      (situacao) => situacao.id !== EnumSitucaoContrato.Aditado &&  situacao.id !== EnumSitucaoContrato.Cancelado
        && (this.contrato.situacaoId == EnumSitucaoContrato.Quitado
        || situacao.id !== EnumSitucaoContrato.Quitado));
         
    return situacoesPermitidas; 
   }

  mounted() {    
   
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => this.pessoas = res.data.items 
    )
     
    new TipoProdutoContratoService().ListarTudo().then(
      res => this.listaTipoEmpreendimento = res.data.items
    )
  
    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id,nome, nomeFantasia', '').then(
      res => this.empresas = res.data.items
    )

    new TipoIndiceService().ListarTudo().then(
      res => this.listarTipoIndice = res.data.items
    )

    const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

    const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.Listar(-1, -1, ['id'],[],'',[], '', '' , '', 'Banco').then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    ) 
  }
}

 


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Empresa } from "@/core/models/cadastros";
import { ParametroCobranca, ParametroFinanceiro, ParametroGatway } from "@/core/models/financeiro";
import { CentroCustoService, ContaCorrenteService, ContaGerencialService, LayoutCobrancaService, ParametroFinanceiroService, ReceitaService, TipoAmortizacaoService, TipoGatewayService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import * as jsonpatch from 'fast-json-patch';
import { TipoOperacaoFinanceiraService } from "@/core/services/contratos";
 
@Component
export default class CadastroParametroDespesa extends Vue {
  @Prop() public item!: ParametroFinanceiro; 
  @Prop() public value!: string;

  tiposCobrancas = [{id:13, nome:'Antes', vencido:false}, {id:14, nome:'Depois', vencido:true}]

  service = new ParametroFinanceiroService();
  gatway = new ParametroGatway();
  cobranca = new ParametroCobranca();
  
  empresas = [] as Empresa[];
  tiposGateways = [];
  listaCentroCusto = [];
  listaContaGerencial = [];
  tiposAmortizacao =[];

  tiposLayouts = [];
  centroCustosOrdenada = [];
  contaGerenciaisOrdenada = [];
  tiposOperacaoContrato = [];
  listaContaCorrentes = [];
  gatways = [];

   

  tab = 0;

  tabActive = 0
  valid = true;
  dialog = false;
  dialogSubconta = false;
  dialogGateway = false;
  gatwayIndex = -1;
  cobrancaIndex = -1; 
  AplicarDiasProtesto = false;
  subConta = false;

  

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {   
    formParametro:HTMLFormElement,
    formGateway:HTMLFormElement,
    formCobranca: HTMLFormElement,
  }

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Tipo", value: "tipoId" },
    { text: "", value: "subconta" },
  ];

  headersCobranca: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Regra de envio de e-mail", value: "posVencimento" },
  ];

  subcontaCard: any = {}

  isSubconta(){
    if(this.item.gatways.some(x => x.galaxPay.isSubconta)){
      this.subConta = true;
      this.subcontaCard = {
        galaxId: this.item.gatways.find(x => x.galaxPay.id)?.galaxPay.id || '',
        galaxHash: this.item.gatways.find(x => x.galaxPay.hash)?.galaxPay.hash || '',
        empresaNome: this.item.empresa?.nome || '',
        ResponsavelNome: this.item.empresa?.responsavel?.nome || ''
      }
    }
  }

  resetSubconta() {
  this.subConta = false;
  this.subcontaCard = {};
}

  @Watch("item", { immediate: true, deep: true })
  onItemChange() {
    this.resetSubconta(); // Reseta os dados antes de verificar
    this.isSubconta();
  }
  
  @Watch("gatway")
  Gatway(){
    if(this.$refs.formGateway){ 
      this.$refs.formGateway.resetValidation()
    }
  }

  @Watch("cobranca")
  Cobranca(){
    if(this.$refs.formCobranca){
      this.$refs.formCobranca.resetValidation()
    }
  }
  @Watch("item")
  Item(){
    if(this.$refs.formParametro){
      this.$refs.formParametro.resetValidation()
    }
  }
  
  observer! : jsonpatch.Observer<ParametroFinanceiro>;

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
      this.isSubconta();
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Validacoes(){
     if(!this.item.empresaId || !this.item.centroCustoReceitalId || !this.item.contaGerenciaReceitalId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 0;
      return true;
    }

   /* if(this.item.gatways.length == 0){
      this.$swal("Aviso", "É necessário adicionar pelo menos um Gatway do financeiro.", "warning");
      this.tabActive = 1;
      return true;
    }*/
  }
   
  Salvar() {
    this.$refs.formParametro.validate();

    if (!this.Validacoes()) {
      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => { 
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  AdicionarCobranca(){
    if (this.$refs.formCobranca.validate()) {
      this.cobranca.posVencimento == true ? this.cobranca.layoutCobrancaId = 14 : this.cobranca.layoutCobrancaId = 13;
      
      if (this.cobrancaIndex == -1) {
         this.item.cobrancas.push(this.cobranca);
      }
      this.cobranca = new ParametroCobranca();
      this.cobrancaIndex = -1;
    }
  } 

  EditarCobranca(item){
    this.cobrancaIndex = this.item.cobrancas.indexOf(item);
    this.cobranca = item;
  }

  ExcluirCobranca(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      focusConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.item.cobrancas!.indexOf(item);
          delete this.item.cobrancas![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
          const index = this.item.cobrancas!.indexOf(item);
          this.item.cobrancas!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  AdicionarGatway(){
    if (this.$refs.formGateway.validate()) {
      if (this.gatwayIndex > -1) {
        Object.assign(this.item.gatways[this.gatwayIndex], this.gatway);
       } else {
        if(this.item.gatways.find((x) => x.tipoId == this.gatway.tipoId)){
          this.$swal("Alerta!","Esse Gateway já se encontra na lista","warning");
          return;
        }

        var objetoClone:any = Object.assign({}, this.gatway);
          objetoClone = new ParametroGatway();
          objetoClone.safra = null;
          objetoClone.galaxPay = null;
          objetoClone.tipoId = this.gatway.tipoId;
          objetoClone.encargoFinanceiro = this.gatway.encargoFinanceiro;  
          objetoClone.galaxPay = this.gatway.galaxPay;
          objetoClone.safra = this.gatway.safra; 

        if(objetoClone.tipoId == 1){
          objetoClone.safra = null;
          objetoClone.galaxPay = null;
        }
        if(objetoClone.tipoId == 2){
          objetoClone.safra = null;
        }
        if(objetoClone.tipoId == 3){
          objetoClone.galaxPay = null;
        }
        this.item.gatways.push(objetoClone);
      }
        this.gatway = new ParametroGatway();
        this.gatwayIndex = -1;
    } 
  }

  EditarGatway(item: ParametroGatway) {
   this.gatwayIndex = this.item.gatways.indexOf(item);
   this.gatway = Object.assign({}, item);
  }

  ExcluirGatway(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.item.gatways!.indexOf(item);
          delete this.item.gatways![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
          const index = this.item.gatways!.indexOf(item);
          this.item.gatways!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  ExibirGateway(item: ParametroGatway){
    this.gatwayIndex = this.item.gatways.indexOf(item);
    this.gatway = item;
    this.dialogGateway = true
  }

  FecharGateway(){
    this.gatway = new ParametroGatway();
    this.gatwayIndex = -1;
    this.dialogGateway = false
  }

  Close() {
    this.FecharGateway();
    this.dialog = false; 
  }

  GerarSubConta(){  
      this.dialogSubconta = true;
  }

  mounted() {
    this.isSubconta()

    new TipoGatewayService().ListarTudo().then(
      res => {this.gatways = res.data.items}
    )

    new TipoAmortizacaoService().ListarTudo().then(
      res => this.tiposAmortizacao = res.data.items
    )

    new LayoutCobrancaService().ListarTudo().then(
      res => this.tiposLayouts = res.data.items
    )

    new TipoGatewayService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.tiposGateways = res.data.items
    )
 
    new CentroCustoService().Listagem().then(
      res => this.listaCentroCusto = res.data 
    )

    new ContaGerencialService().Listagem().then(
      res => this.listaContaGerencial = res.data
    );

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.empresas = res.data.items
    );

    new CentroCustoService().Listagem().then(
      (res) => {this.centroCustosOrdenada = res.data;},
    );

    new ContaGerencialService().Listagem().then(
      (res) => {this.contaGerenciaisOrdenada = res.data;},
    );

    const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

    const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.ListarTudo().then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    )
  } 
}


import { Encargo, Receita } from "@/core/models/financeiro";
import { TipoOperacaoFinanceiraService } from "@/core/services/contratos";
import { ContaCorrenteService, ParametroFinanceiroService, ReceitaService } from "@/core/services/financeiro";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
 
@Component
export default class EditarParcelaDespesa extends Vue {
  @Prop() public item!: Receita;
  @Prop() public value!: string;

  service = new ReceitaService();
  valid = true;
  dialog = false;
 // AplicarDiasProtesto = false;

  listaGateway = [];
  tiposOperacaoContrato = [];
  listaContaCorrentes:any = [];

  taxaBoleto:number = 0;

  get codigoBanco() {
    const contaCorrente = this.listaContaCorrentes.find((x: any) => this.item.contaCorrenteId == x.id);
    return contaCorrente ? contaCorrente.banco.codigo : '';
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  } 

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterParametroPorId(this.item.empresaId);
    }
  }

  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      res => { 
        this.listaGateway = res.data.gatways;
      }
    )
  }

  ObterParametroGatway(item){
    if(item){
      this.item.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.item.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    } 
  }
  
  Salvar() {
    this.service.AtualizarEncargos(this.item.id, this.item.encargoFinanceiro, this.item.tipoGatewayId, this.item.tipoOperacaoId, this.item.contaCorrenteId, this.taxaBoleto).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
        }
    });
  }

  Close() {
    this.dialog = false;
    this.taxaBoleto = 0;
  }  

  mounted(){
    const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

  const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.Listar(-1, -1, ['id'],[],'',[], '', '' , '', 'Banco').then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    ) 
  }
}
